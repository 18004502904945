import axios from "axios";

const state = {
	status: "",
	user: null,
};

const getters = {
	isLoggedIn(state) {
		return !!state.user;
	},
	authStatus: (state) => state.status,
	userInfo: (state) => {
		return state.user;
	},
	isAdmin(state) {
		if (!state.user || !state.user.userRoles) {
			return false;
		}
		let isAdmin = state.user.userRoles.includes("super_admin") || state.user.userRoles.includes("admin");
		return isAdmin ?? false;
	},
};

const mutations = {
	auth_request(state) {
		state.status = "loading";
	},
	auth_success(state, userInfo) {
		state.status = "success";
		state.user = userInfo;
	},
	auth_error(state) {
		state.status = "error";
	},
	logout(state) {
		state.status = "";
		state.user = null;
	},
	session_ended(state) {
		state.user = null;
	},
};

const actions = {
	login({ commit }, user) {
		commit("session_ended");
		return new Promise((resolve, reject) => {
			commit("auth_request");
			axios({ url: "/api/auth/login", data: user, method: "POST" })
				.then((resp) => {
					const userInfo = resp.data.user;
					commit("auth_success", userInfo);
					resolve(resp);
				})
				.catch((err) => {
					commit("auth_error");
					reject(err);
				});
		});
	},
	register({ commit }, user) {
		return new Promise((resolve, reject) => {
			commit("auth_request");
			axios({ url: "/api/users", data: user, method: "POST" })
				.then((resp) => {
					const userInfo = resp.data.user;
					commit("auth_successful register", userInfo);
					resolve(resp);
				})
				.catch((err) => {
					commit("auth_error");
					reject(err);
				});
		});
	},
	logout({ commit }) {
		return new Promise((resolve, reject) => {
			commit("logout");
			axios({ url: "/api/auth/logout", data: {}, method: "POST" })
				.then((resp) => {
					commit("session_ended");
					resolve(resp);
				})
				.catch((err) => {
					commit("auth_error");
					reject(err);
				});
			window.sessionStorage.clear();
			resolve();
		});
	},
	verifySession({ commit }) {
		return new Promise((resolve) => {
			commit("auth_request");
			axios({ url: "/api/auth/session", data: {}, method: "GET" })
				.then((resp) => {
					const userInfo = resp.data;
					commit("auth_success", userInfo);
					resolve(resp);
				})
				.catch(() => {
					commit("session_ended");
					resolve();
				});
		});
	},
};

export default {
	state,
	mutations,
	actions,
	getters,
};
