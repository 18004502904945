import Vue from "vue";
import VueRouter from "vue-router";
import store from "../store/index.js";

Vue.use(VueRouter);

const routes = [
	{
		path: "/",
		redirect: "/login",
	},
	{
		path: "/login",
		name: "Login",
		component: () => import("@/views/Public/Login.vue"),
		meta: {
			guest: true,
			title: "Chemformation - Login",
		},
	},
	{
		path: "/contact",
		name: "Contact",
		component: () => import("@/views/Public/Contact.vue"),
		meta: {
			title: "Chemformation - Contact Us",
		},
	},
	{
		path: "/request",
		name: "RequestAccess",
		component: () => import("@/views/Public/Request.vue"),
		meta: {
			requiresAuth: false,
			guest: true,
			title: "Chemformation - Request Access",
		},
	},
	{
		path: "/supplier-partner",
		name: "SupplierPartner",
		component: () => import("@/views/Public/Supplier.vue"),
		meta: {
			guest: true,
			title: "Chemformation - Contact Us",
		},
	},
	{
		path: "/tutorial",
		name: "VideoDemo",
		component: () => import("@/views/Public/VideoDemo.vue"),
		meta: {
			guest: true,
			title: "Chemformation - Tutorial",
		},
	},
	{
		path: "/forgot-password",
		name: "ForgotPassword",
		component: () => import("@/views/Public/ForgotPassword.vue"),
		meta: {
			requiresAuth: false,
			guest: true,
			title: "Chemformation - Reset Password",
		},
	},
	{
		path: "/reset-password",
		name: "ResetPassword",
		component: () => import("@/views/Public/ResetPassword.vue"),
		meta: {
			requiresAuth: false,
			guest: true,
			title: "Chemformation - Reset Password",
		},
	},
	{
		path: "/product-search",
		name: "ProductSearch",
		component: () => import("@/views/Private/ProductSearch.vue"),
		meta: {
			requiresAuth: true,
			title: "Chemformation - Product Search",
		},
	},
	{
		path: "/internet-search",
		name: "InternetSearch",
		component: () => import("@/views/Private/InternetSearch.vue"),
		meta: {
			requiresAuth: true,
			title: "Chemformation - Internet Search",
		},
	},
	{
		path: "/formulations",
		name: "Formulations",
		component: () => import("@/views/Private/Formulations.vue"),
		meta: {
			requiresAuth: true,
			title: "Chemformation - formulation Search",
		},
	},
	{
		path: "/faq",
		name: "FAQ",
		component: () => import("@/views/Private/FAQ.vue"),
		meta: {
			requiresAuth: true,
			title: "Chemformation - FAQ Documents",
		},
	},
	{
		path: "/glossary",
		name: "Glossary",
		component: () => import("@/views/Private/Glossary.vue"),
		meta: {
			requiresAuth: true,
			title: "Chemformation - Glossary",
		},
	},
	{
		path: "/account",
		name: "Account",
		component: () => import("@/views/Private/Account.vue"),
		meta: {
			title: "Chemformation - Account Information",
		},
	},
	{
		path: "/visit-report",
		name: "VisitReport",
		component: () => import("@/views/Private/VisitReport.vue"),
		meta: {
			requiresAuth: true,
			title: "Chemformation - Visit Report",
		},
	},
	{
		path: "/vendor-report",
		name: "VendorReport",
		component: () => import("@/views/Private/VendorReport.vue"),
		meta: {
			requiresAuth: true,
			title: "Chemformation - Vendor Report",
		},
	},
	{
		path: "/mail",
		name: "Mail",
		component: () => import("@/views/Private/Mail.vue"),
		meta: {
			requiresAuth: true,
			title: "Chemformation - Mail Messages",
		},
	},
	{
		path: "/mail-message",
		name: "MailMessage",
		component: () => import("@/views/Private/MailMessage.vue"),
		meta: {
			requiresAuth: true,
			title: "Chemformation - Mail Messages",
		},
	},
	{
		path: `/cross-reference`,
		name: "Cross Reference Preview",
		component: () => import("@/views/Private/CrossReference.vue"),
		meta: {
			requiresAuth: true,
			title: "Chemformation - Cross References",
		},
	},
];

const router = new VueRouter({
	mode: "history",
	base: process.env.BASE_URL,
	routes,
});

//redirects user if trying to access a page that requires login
router.beforeEach(async (to, from, next) => {
	if (to.matched.some((record) => record.meta.requiresAuth)) {
		await store.dispatch("verifySession");

		if (store.getters.isLoggedIn) {
			if (!store.getters.userInfo.acceptedTerms) {
				next("/account");
			}
			next();
			return;
		}

		next("/login");
	} else {
		next();
	}
});

router.afterEach((to) => {
	Vue.nextTick(() => {
		document.title = to.meta.title;
	});

	// refresh system config
	store.dispatch("getConfig").catch((error) => {
		console.log("Error during fetch system config.", error);
	});
});

export default router;
