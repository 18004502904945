import Vue from "vue";
import Vuetify from "vuetify/lib/framework";

Vue.use(Vuetify);

const vuetify = new Vuetify({
	theme: {
		breakpoint: {
			thresholds: {
				xs: 767,
				sm: 960,
				md: 1260,
				lg: 1800,
			},
			scrollBarWidth: 5,
		},
		options: {
			customProperties: true,
		},
		themes: {
			light: {
				primary: "#1264f6",
				secondary: "#f4f4f6",
				accent: "#eff5ff",
				error: "#ff3823",
				info: "#2196F3",
				success: "#629c44",
				warning: "#ff6624",
			},
		},
	},
});

export default vuetify;
