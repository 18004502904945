import axios from "axios";

const state = {
	status: "",
	systemMessage: { systemMessageId: null, message: null },
	hiddenBanners: [],
};

const getters = {
	getSystemMessage(state) {
		return state.systemMessage;
	},
	isBannerVisible(state) {
		return state.systemMessage && !state.hiddenBanners.includes(state.systemMessage.systemMessageId);
	},
};

const mutations = {
	system_request(state) {
		state.status = "fetching";
	},
	system_success(state, result) {
		if (result.systemMessage) {
			state.systemMessage = result.systemMessage;
		} else {
			state.systemMessage = null;
		}

		state.status = "success";
	},
	system_error(state) {
		state.status = "error";
	},
	system_set_banner_dismiss(state) {
		state.hiddenBanners.push(state.systemMessage.systemMessageId);
		state.status = "success";
	},
};

const actions = {
	getConfig({ commit }) {
		return new Promise((resolve, reject) => {
			commit("system_request");
			axios({ url: "/api/system/list", method: "GET" })
				.then((resp) => {
					console.log("successful fetch", resp);
					const config = resp.data.listResults;
					commit("system_success", config);
					resolve(resp);
				})
				.catch((err) => {
					commit("system_error");
					reject(err);
				});
		});
	},
	hideBanner({ commit }) {
		commit("system_request");
		commit("system_set_banner_dismiss");
	},
};

export default {
	state,
	mutations,
	actions,
	getters,
};
