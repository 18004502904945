<template>
	<div
		v-if="
			($route.path !== '/login' &&
				$route.path !== '/request' &&
				$route.path !== '/contact' &&
				$route.path !== '/tutorial') ||
			isLoggedIn
		"
	>
		<div v-if="isMobile">
			<v-navigation-drawer v-model="drawer" class="navigation" app>
				<template v-if="isLoggedIn">
					<router-link
						v-for="link in accessibleLinks"
						:key="link.name"
						:to="link.path"
						:class="[$route.path == link.path ? 'nav-link-active' : null]"
						class="nav-link"
					>
						<v-list nav>
							<v-list-item :key="link.title" link v-if="isVisible(link)">
								<v-list-item-icon class="nav-icon">
									<v-icon>{{ link.icon }}</v-icon>
								</v-list-item-icon>

								<v-list-item-content>
									<v-list-item-title class="nav-title">{{ link.name }}</v-list-item-title>
								</v-list-item-content>
							</v-list-item>
						</v-list>
					</router-link>
				</template>
				<template v-else>
					<router-link
						v-for="link in publicLinks"
						:key="link.name"
						:to="link.path"
						:class="[$route.path == link.path ? 'nav-link-active' : null]"
						class="nav-link"
					>
						<v-list nav>
							<v-list-item :key="link.title" link>
								<v-list-item-icon class="nav-icon">
									<v-icon>{{ link.icon }}</v-icon>
								</v-list-item-icon>

								<v-list-item-content>
									<v-list-item-title class="nav-title">{{ link.name }}</v-list-item-title>
								</v-list-item-content>
							</v-list-item>
						</v-list>
					</router-link>
				</template>
			</v-navigation-drawer>

			<v-app-bar class="top-navigation" app height="60">
				<v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>
				<img src="@/assets/svg/Chemformation_logo_Horz.svg" height="25" />

				<v-spacer></v-spacer>

				<v-menu offset-y v-if="user">
					<template v-slot:activator="{ on, attrs }">
						<v-btn icon v-bind="attrs" v-on="on">
							<v-icon size="36">mdi-account-circle</v-icon>
						</v-btn>
					</template>

					<v-list>
						<v-list-item link>
							<v-list-item-content>
								<v-list-item-title class="text-h6">
									{{ user.firstName }} {{ user.lastName }}
								</v-list-item-title>
								<v-list-item-subtitle>{{ user.email }}</v-list-item-subtitle>
							</v-list-item-content>
						</v-list-item>

						<v-divider></v-divider>

						<v-list-item @click="$router.push('/account')">
							<v-list-item-title>My Account</v-list-item-title>
						</v-list-item>

						<v-list-item @click="logout()">
							<v-list-item-title>Logout</v-list-item-title>
						</v-list-item>
					</v-list>
				</v-menu>
			</v-app-bar>
		</div>
		<div v-else class="nav top-navigation">
			<div class="heading">
				<img
					v-if="isLogoVisible"
					src="@/assets/svg/Chemformation_logo_Horz.svg"
					hspace="0"
					vspace="0"
					style="height: 100%"
				/>
				<div class="social nav-socials">
					<a href="https://www.linkedin.com/company/chemformation" target="_blank">
						<img src="@/assets/LinkedIn-social-icons.png" alt="" align="absMiddle" border="0" />
					</a>
				</div>
			</div>
			<div id="menu">
				<template v-if="isLoggedIn">
					<router-link
						v-for="link in accessibleLinks"
						:key="link.name"
						:to="link.path"
						:class="[$route.path == link.path ? 'nav-link-active' : null]"
						class="custom-button mi ui-menu nav-link"
					>
						{{ link.name }}
					</router-link>
					<button
						type="button"
						class="custom-button mi ui-menu nav-link"
						style="color: #ba0c2f"
						v-if="isLogoVisible"
						@click="logout()"
					>
						Logout
					</button>
				</template>
				<!-- <template v-else>
					<router-link
						v-for="link in publicLinks"
						:key="link.name"
						:to="link.path"
						:class="[$route.path == link.path ? 'nav-link-active' : null]"
						class="custom-button mi ui-menu nav-link"
					>
						{{ link.name }}
					</router-link>
				</template> -->
			</div>
		</div>
	</div>

	<div v-else>
		<v-app-bar class="top-navigation" app height="auto" :style="{ padding: navPadding }">
			<v-row>
				<v-col cols="12" sm="6">
					<div class="logo-container">
						<router-link to="/login">
							<img class="logo custom-button" src="@/assets/svg/Chemformation_logo_Primary.svg" />
						</router-link>
					</div>
				</v-col>
				<v-col cols="12" sm="6" class="nav-actions">
					<div class="custom-button-container">
						<router-link to="/request" class="uppercase custom-button">Request Access</router-link>
						<router-link to="/contact" class="uppercase custom-button">Contact Us</router-link>
						<router-link to="/tutorial" class="uppercase custom-button">Video Demo</router-link>
					</div>
				</v-col>
			</v-row>
		</v-app-bar>
	</div>
</template>

<script>
export default {
	name: "Navigation",
	data() {
		return {
			publicLinks: [
				{
					path: "/login",
					name: "Login",
					icon: "mdi-login",
				},
				{
					path: "/contact",
					name: "Contact Us",
					icon: "mdi-card-account-mail",
				},
				{
					path: "/request",
					name: "Request Access",
					icon: "mdi-account-plus",
				},
				{
					path: "/supplier-partner",
					name: "Supplier Partner",
					icon: "mdi-account-tie",
				},
				{
					path: "/tutorial",
					name: "Video Demo",
					icon: "mdi-video",
				},
			],
			privateLinks: [
				{
					path: "/product-search",
					name: "Product Search",
					isAdmin: false,
					icon: "mdi-flask",
				},
				{
					path: "/internet-search",
					name: "Internet Search",
					isAdmin: false,
					icon: "mdi-magnify",
				},
				{
					path: "/formulations",
					name: "Formulations",
					isAdmin: false,
					icon: "mdi-mortar-pestle-plus",
				},
				{
					path: "/faq",
					name: "FAQ",
					isAdmin: false,
					icon: "mdi-frequently-asked-questions",
				},
				{
					path: "/glossary",
					name: "Glossary",
					isAdmin: false,
					icon: "mdi-book-open-variant",
				},
				{
					path: "/contact",
					name: "Contact Us",
					isAdmin: false,
					icon: "mdi-card-account-mail",
				},
				{
					path: "/account",
					name: "Account",
					isAdmin: false,
					icon: "mdi-account",
				},
				{
					path: "/visit-report",
					name: "Visit Report",
					isAdmin: true,
					icon: "mdi-note-search-outline",
				},
				{
					path: "/vendor-report",
					name: "Vendor Report",
					isAdmin: true,
					icon: "mdi-account-multiple",
				},
				{
					path: "/mail",
					name: "Mail",
					isAdmin: true,
					icon: "mdi-email-search-outline",
				},
			],
			isLogoVisible: true,
			isMobile: false,
			drawer: null,
			user: null,
			navPadding: "0",
		};
	},
	methods: {
		logout() {
			this.$store
				.dispatch("logout")
				.then(() => {
					this.user = null;
					this.$router.push("/");
				})
				.catch((error) => {
					console.log(error);
				});
		},
		isVisible(link) {
			let isVisible = link.isAdmin ? this.isAdmin : true;
			return isVisible;
		},
		emitNavHeight() {
			const elements = document.getElementsByClassName("top-navigation");
			console.log(elements.length, elements, elements[0]);
			const paddingTop = elements.length > 0 ? elements[0].clientHeight + "px" : "0px";
			this.$emit("navHeight", paddingTop);
			console.log(paddingTop);

			this.navPadding = this.isMobile ? "0" : "2rem 3rem";
		},
	},
	computed: {
		isLoggedIn() {
			return this.$store.getters.isLoggedIn;
		},
		isAdmin() {
			return this.$store.getters.isAdmin;
		},
		accessibleLinks() {
			return this.privateLinks.filter((link) => this.isVisible(link));
		},
	},
	updated() {
		this.user = this.$store.getters.userInfo;
		this.emitNavHeight();
	},
	mounted() {
		this.isMobile = this.$vuetify.breakpoint.xsOnly || this.$vuetify.breakpoint.smOnly;
		this.user = this.$store.getters.userInfo;
		if (window.location !== window.parent.location) {
			// The page is in an iframe
			this.isLogoVisible = false;
		}

		window.addEventListener("resize", this.emitNavHeight);
	},
};
</script>

<style lang="scss" scoped>
.nav {
	box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14),
		0px 1px 10px 0px rgba(0, 0, 0, 0.12);

	&-link {
		border: 1px solid #a6c9e2;
		border-radius: 1px;
		padding: 0 0.2rem;
		background: #fcfdfd url("~@/assets/ui-bg_inset-hard_100_fcfdfd_1x100.png") 50% bottom repeat-x;
		font-size: 1.5rem;
		color: #003399;
		font-family: "Lucida Grande", "Lucida Sans", Arial, sans-serif;
		line-height: initial;
		text-decoration: none;

		&:not(:last-child) {
			margin-right: 0.4rem;
		}

		&-active {
			border: 1px solid #79b7e7;
			background: #d0e5f5 url("~@/assets/ui-bg_glass_75_d0e5f5_1x400.png") 50% 50% repeat-x;
			font-weight: bold;
			color: #1d5987;
		}

		&.mi {
			display: inline-block;
			width: 130px;
			margin-bottom: 2px;
			text-align: left;
			white-space: nowrap;
		}
	}

	&-socials {
		a {
			display: inline-block;
			max-width: 30px;

			img {
				width: 100%;
				height: auto;
			}
		}
	}
}

.navigation {
	background-color: #fff !important;

	a {
		display: block !important;
		// padding: 0;
		margin-right: 0 !important;

		text-decoration: none;
		color: #000 !important;
	}

	.nav-link {
		color: transparent;
		border: none !important;
		font-size: 1.5rem !important;

		.nav-icon {
			margin-right: 1rem;
		}
	}

	.nav-link-active {
		border: none !important;
		background: none;
	}

	.router-link-active {
		background-color: rgba(121, 183, 231, 0.151);
	}
}

.top-navigation {
	background-color: #fff !important;
}
</style>

<style lang="scss" scoped>
.uppercase {
	text-transform: uppercase;
}

.nav-actions {
	display: flex;
	align-items: flex-end;
}

.custom-button-container {
	display: flex;
	flex: 1;
	justify-content: center;
	align-items: center;
}

.custom-button {
	display: flex;
	align-items: center;
	padding: 12px 24px;
	cursor: pointer;
	font-size: 18px;
	position: relative;
	background-color: #fff !important;
}

.custom-button::before {
	content: "";
	position: absolute;
	top: -1.5rem;
	left: 50%;
	transform: translateX(-50%);
	border-left: 1.5rem solid transparent;
	border-right: 1.5rem solid transparent;
	border-top: 1.5rem solid #ba0c2f;
	opacity: 0;
	transition: opacity 0.3s;
}

.custom-button.nav-link-active::before {
	opacity: 1;
}

.custom-button:hover {
	color: #ba0c2f;
}

.custom-button:hover::before {
	opacity: 1;
}

.logo-container {
	flex-shrink: 0;
	display: flex;
	justify-content: center;
}

.logo {
	height: 12rem;
}
</style>
