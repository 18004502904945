import GHS01 from "../assets/GHS01.png";
import GHS02 from "../assets/GHS02.png";
import GHS03 from "../assets/GHS03.png";
import GHS04 from "../assets/GHS04.png";
import GHS05 from "../assets/GHS05.png";
import GHS06 from "../assets/GHS06.png";
import GHS07 from "../assets/GHS07.png";
import GHS08 from "../assets/GHS08.png";
import GHS09 from "../assets/GHS09.png";

// plugins/Constants.js
let Constants = {
	Hazards: [
		{
			GHS: "GHS01 Explosive",
			Asset: GHS01,
		},
		{
			GHS: "GHS02 Flammable",
			Asset: GHS02,
		},
		{
			GHS: "GHS03 Oxidizing",
			Asset: GHS03,
		},
		{
			GHS: "GHS04 Compressed",
			Asset: GHS04,
		},
		{
			GHS: "GHS05 Corrosive",
			Asset: GHS05,
		},
		{
			GHS: "GHS06 Toxic",
			Asset: GHS06,
		},
		{
			GHS: "GHS07 Harmful",
			Asset: GHS07,
		},
		{
			GHS: "GHS08 Health Hazard",
			Asset: GHS08,
		},
		{
			GHS: "GHS09 Environmental hazard",
			Asset: GHS09,
		},
	],
};

export default Constants;
