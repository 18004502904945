import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";
//modules
import authentication from "./modules/authentication.js";
import system from "./modules/system.js";

Vue.use(Vuex);

export default new Vuex.Store({
	modules: {
		authentication,
		system,
	},
	plugins: [
		createPersistedState({
			storage: window.sessionStorage,
		}),
	],
});
