<template>
	<div class="editor" @click="handleClick($event)">
		<editor-content :editor="editor" class="editor__reader" />
	</div>
</template>

<script>
import { Editor, EditorContent } from "@tiptap/vue-2";
import StarterKit from "@tiptap/starter-kit";
import TaskList from "@tiptap/extension-task-list";
import TaskItem from "@tiptap/extension-task-item";
import Highlight from "@tiptap/extension-highlight";
import Blockquote from "@tiptap/extension-blockquote";
import Link from "@tiptap/extension-link";
import Table from "@tiptap/extension-table";
import TableCell from "@tiptap/extension-table-cell";
import TableHeader from "@tiptap/extension-table-header";
import TableRow from "@tiptap/extension-table-row";
import Image from "@tiptap/extension-image";
import TextAlign from "@tiptap/extension-text-align";
import { Color } from "@tiptap/extension-color";
import TextStyle from "@tiptap/extension-text-style";
import Paragraph from "@tiptap/extension-paragraph";
import { mergeAttributes } from "@tiptap/core";

const CustomTableCell = TableCell.extend({
	addAttributes() {
		return {
			// extend the existing attributes …
			...this.parent?.(),
			// and add a new one …
			backgroundColor: {
				default: null,
				parseHTML: (element) => element.getAttribute("data-background-color"),
				renderHTML: (attributes) => {
					return {
						"data-background-color": attributes.backgroundColor,
						style: `background-color: ${attributes.backgroundColor}`,
					};
				},
			},
			clearCellFormatting: {
				default: null,
				parseHTML: (element) => element.getAttribute("data-clearCellFormatting"),
				renderHTML: (attributes) => {
					return {
						"data-clearCellFormatting": attributes.clearCellFormatting,
						style: `background-color: ${attributes.clearCellFormatting}; border: ${attributes.clearCellFormatting};`,
					};
				},
			},
		};
	},
	renderHTML({ HTMLAttributes }) {
		let totalWidth = 0;
		let fixedWidth = true;

		// console.log("cell", HTMLAttributes, HTMLAttributes["colwidth"]);

		if (HTMLAttributes["data-clearCellFormatting"]) {
			HTMLAttributes.style += `background-color: ${HTMLAttributes["data-clearCellFormatting"]}; border: ${HTMLAttributes["data-clearCellFormatting"]};`;
		}

		if (HTMLAttributes.colwidth) {
			HTMLAttributes.colwidth.forEach((col) => {
				if (!col) {
					fixedWidth = false;
				} else {
					totalWidth += col;
				}
			});
		} else {
			fixedWidth = false;
		}

		if (fixedWidth && totalWidth > 0) {
			HTMLAttributes.style += `width: ${totalWidth}px;`;
		} else if (totalWidth && totalWidth > 0) {
			HTMLAttributes.style += `min-width: ${totalWidth}px`;
		} else {
			HTMLAttributes.style += null;
		}

		return ["td", mergeAttributes(this.options.HTMLAttributes, HTMLAttributes), 0];
	},
});

const CustomTableRow = TableRow.extend({
	addAttributes() {
		return {
			// extend existing attributes
			...this.parent?.(),
			// add the 'data-productId' attribute
			productId: {
				default: null,
				parseHTML: (element) => element.getAttribute("data-productId"),
				renderHTML: (attributes) => {
					if (!attributes.productId) {
						return {};
					}
					return {
						"data-productId": attributes.productId,
					};
				},
			},
		};
	},
});

const CustomParagraph = Paragraph.extend({
	addAttributes() {
		return {
			...this.parent?.(),
			// Define the new attributes you want to support
			style: {
				default: null,
			},
			"data-productid": {
				default: null,
			},
		};
	},
	// Ensure the HTML rendering logic includes these attributes
	renderHTML({ HTMLAttributes }) {
		return ["p", HTMLAttributes, 0];
	},
});

export default {
	components: {
		EditorContent,
	},
	props: {
		textContent: {
			type: String,
			default: "",
		},
		interactive: {
			type: Boolean,
			default: true,
		},
	},
	data() {
		return {
			editor: null,
		};
	},
	methods: {
		handleClick(event) {
			// Ensure the editor is focused on click
			this.$nextTick(() => this.editor.view.dom.focus());

			// Starting from the event target, climb up the DOM tree
			// to find if a paragraph with 'data-productid' was clicked
			let target = event.target;
			while (target && target !== this.$el) {
				if (target.matches("p[data-productid]")) {
					this.$emit("productSelected", target.getAttribute("data-productid"));
					break; // Stop the loop once the paragraph is found and event emitted
				}
				target = target.parentNode;
			}
		},
	},
	watch: {
		textContent(textContent) {
			// HTML
			const isSame = this.editor.getHTML() === textContent;

			// JSON
			// const isSame = this.editor.getJSON().toString() === textContent.toString()

			if (isSame) {
				return;
			}

			this.editor.commands.setContent(this.textContent, false);
		},
	},
	mounted() {
		this.editor = new Editor({
			extensions: [
				StarterKit,
				TextAlign.configure({
					types: ["heading", "paragraph", "table"],
				}),
				Highlight,
				TaskList,
				TaskItem,
				Link,
				Table.configure({
					resizable: true,
				}),
				CustomTableRow,
				TableHeader,
				CustomTableCell,
				CustomParagraph,
				Image.configure({
					inline: true,
					allowBase64: true,
					HTMLAttributes: {
						style: "max-width: 450px",
					},
				}),
				TextStyle,
				Color,
				Blockquote,
			],
			content: this.textContent,
			editable: this.interactive, //interactive prop controls if edit is active or not
			onUpdate: () => {
				// HTML
				this.$emit("input", this.editor.getHTML());

				// JSON
				// this.$emit('input', this.editor.getJSON())
			},
		});
	},

	beforeDestroy() {
		this.editor.destroy();
	},
};
</script>

<style lang="scss" scoped>
.editor {
	position: relative;
	display: block;
	border-radius: 3px;
	font-size: 10pt;

	&__header {
		display: flex;
		align-items: center;
		flex: 0 0 auto;
		flex-wrap: wrap;
		padding: 0.25rem;
		border-bottom: 3px solid #0d0d0d;
	}
	&__content {
		padding: 1.25rem 1rem;
		flex: 1 1 auto;
		overflow-x: hidden;
		overflow-y: auto;
		-webkit-overflow-scrolling: touch;
	}
	&__footer {
		display: flex;
		flex: 0 0 auto;
		align-items: center;
		justify-content: space-between;
		flex-wrap: wrap;
		white-space: nowrap;
		border-top: 3px solid #0d0d0d;
		font-size: 12px;
		font-weight: 600;
		color: #0d0d0d;
		white-space: nowrap;
		padding: 0.25rem 0.75rem;
	}
	/* Some information about the status */
	&__status {
		display: flex;
		align-items: center;
		border-radius: 5px;
		&::before {
			content: " ";
			flex: 0 0 auto;
			display: inline-block;
			width: 0.5rem;
			height: 0.5rem;
			background: rgba(#0d0d0d, 0.5);
			border-radius: 50%;
			margin-right: 0.5rem;
		}
		&--connecting::before {
			background: #616161;
		}
		&--connected::before {
			background: #b9f18d;
		}
	}
	&__name {
		button {
			background: none;
			border: none;
			font: inherit;
			font-size: 12px;
			font-weight: 600;
			color: #0d0d0d;
			border-radius: 0.4rem;
			padding: 0.25rem 0.5rem;
			&:hover {
				color: #fff;
				background-color: #0d0d0d;
			}
		}
	}
}
</style>

<style lang="scss">
/* Basic editor styles */
.ProseMirror {
	> * + * {
		margin-top: 0.75em;
	}
	ul,
	ol {
		padding: 0 1rem;
	}
	h1,
	h2,
	h3,
	h4,
	h5,
	h6 {
		line-height: 1.1;
	}
	code {
		background-color: rgba(#616161, 0.1);
		color: #616161;
	}
	pre {
		background: #0d0d0d;
		color: #fff;
		font-family: "JetBrainsMono", monospace;
		padding: 0.75rem 1rem;
		border-radius: 0.5rem;
		code {
			color: inherit;
			padding: 0;
			background: none;
			font-size: 0.8rem;
		}
	}
	mark {
		background-color: #faf594;
	}
	img {
		max-width: 100%;
		height: auto;
	}
	hr {
		margin: 1rem 0;
	}
	blockquote {
		padding-left: 1rem;
		border-left: 2px solid rgba(#0d0d0d, 0.1);
	}
	hr {
		border: none;
		border-top: 2px solid rgba(#0d0d0d, 0.1);
		margin: 2rem 0;
	}
	ul[data-type="taskList"] {
		list-style: none;
		padding: 0;
		li {
			display: flex;
			align-items: center;
			> label {
				flex: 0 0 auto;
				margin-right: 0.5rem;
				user-select: none;
			}
			> div {
				flex: 1 1 auto;
			}
		}
	}
}

/* Table-specific styling */
.ProseMirror {
	table {
		border-collapse: collapse;
		table-layout: fixed;
		// width: 100%;
		margin: 0;
		overflow: hidden;
		td,
		th {
			min-width: 1em;
			border: 2px solid #ced4da;
			padding: 3px 5px;
			vertical-align: top;
			box-sizing: border-box;
			position: relative;
			> * {
				margin-bottom: 0;
			}
		}
		th {
			font-weight: bold;
			text-align: left;
			background-color: #f1f3f5;
		}
		.selectedCell:after {
			z-index: 2;
			position: absolute;
			content: "";
			left: 0;
			right: 0;
			top: 0;
			bottom: 0;
			background: rgba(200, 200, 255, 0.4);
			pointer-events: none;
		}
		.column-resize-handle {
			position: absolute;
			right: -2px;
			top: 0;
			bottom: -2px;
			width: 4px;
			background-color: #adf;
			pointer-events: none;
		}
		p {
			margin: 0;
		}
	}
}
.tableWrapper {
	overflow-x: auto;
}
.resize-cursor {
	cursor: ew-resize;
	cursor: col-resize;
}
</style>
