<template>
	<v-expand-transition>
		<v-banner id="system-banner" v-if="isBannerVisible">
			<v-icon slot="icon" color="#b94a48" size="36">mdi-alert</v-icon>
			<v-container fluid>
				<v-row>
					<v-col cols="12" sm="10">
						<RichText :textContent="currentSystemMessage.message" :interactive="false" />
					</v-col>
					<v-col cols="12" sm="2" class="d-flex justify-end align-center">
						<v-btn class="ma-2 pulse-button" outlined color="#b94a48" @click="dismiss">Dismiss</v-btn>
					</v-col>
				</v-row>
			</v-container>
		</v-banner>
	</v-expand-transition>
</template>

<script>
import RichText from "@/components/RichText.vue";

export default {
	name: "SystemBanner",
	components: {
		RichText,
	},
	data() {
		return {};
	},
	methods: {
		dismiss() {
			this.$store.dispatch("hideBanner").catch((error) => {
				console.log(error);
			});
		},
	},
	computed: {
		isBannerVisible() {
			return (
				this.$store.getters.isBannerVisible &&
				this.currentSystemMessage.message &&
				this.currentSystemMessage.message.length > 0
			);
		},
		currentSystemMessage() {
			return this.$store.getters.getSystemMessage;
		},
	},
};
</script>

<style lang="scss" scoped>
@mixin transform($transforms...) {
	// combine the passed transforms into a space separated list
	$transform-list: join($transforms, null, space);

	// print out the transforms
	-webkit-transform: $transform-list;
	-moz-transform: $transform-list;
	-ms-transform: $transform-list;
	-o-transform: $transform-list;
	transform: $transform-list;
}

#system-banner {
	background-color: #f2dede;
	color: #b94a48;
	border-color: #eed3d7;

	.pulse-button {
		position: relative;
		font-size: 1.3em;
		cursor: pointer;
		box-shadow: 0 0 0 0 rgba(#b94a48, 0.5);
		-webkit-animation: pulse 5s infinite;
	}

	.pulse-button:hover {
		-webkit-animation: none;
	}

	@-webkit-keyframes pulse {
		0% {
			@include transform(scale(0.9));
		}
		70% {
			@include transform(scale(1));
			box-shadow: 0 0 0 50px rgba(#b94a48, 0);
		}
		100% {
			@include transform(scale(0.9));
			box-shadow: 0 0 0 0 rgba(#b94a48, 0);
		}
	}
}
</style>
